import AdminAiAssistantCancelProcessing from 'client/shared/graphql-mutations/AdminAiAssistantCancelProcessing.graphql';
import AdminAiAssistantPostMessage from 'client/shared/graphql-mutations/AdminAiAssistantPostMessage.graphql';
import AdminDuplicateSimulation from 'client/shared/graphql-mutations/AdminDuplicateSimulation.graphql';
import CreateAndLoginDemoUser from 'client/shared/graphql-mutations/CreateAndLoginDemoUser.graphql';
import CreateDemoUser from 'client/shared/graphql-mutations/CreateDemoUser.graphql';
import InteractWithIdea from 'client/shared/graphql-mutations/InteractWithIdea.graphql';
import CreateIdea from 'client/shared/graphql-mutations/CreateIdea.graphql';
import AdminUpdateVisualizationState from 'client/shared/graphql-mutations/AdminUpdateVisualizationState.graphql';
import AdminSaveNewVisualization from 'client/shared/graphql-mutations/AdminSaveNewVisualization.graphql';
import SuperAdminImportTranslations from 'client/shared/graphql-mutations/SuperAdminImportTranslations.graphql';
import ReorderQuestionSet from 'client/shared/graphql-mutations/ReorderQuestionSet.graphql';
import SuperAdminDownloadBenchmarkReportRequestData from 'client/shared/graphql-mutations/SuperAdminDownloadBenchmarkReportRequestData.graphql';
import SuperAdminPublishReportRequest from 'client/shared/graphql-mutations/SuperAdminPublishBenchmarkReportRequest.graphql';
import SuperAdminDeleteReportRequest from 'client/shared/graphql-mutations/SuperAdminDeleteBenchmarkReportRequest.graphql';
import SuperAdminUpsertReportRequest from 'client/shared/graphql-mutations/SuperAdminUpsertBenchmarkReportRequest.graphql';
import SuperAdminUpdateThirdPartyRecord from 'client/shared/graphql-mutations/SuperAdminUpdateThirdPartyRecord.graphql';
import SuperAdminCreateThirdPartyRecord from 'client/shared/graphql-mutations/SuperAdminCreateThirdPartyRecord.graphql';
import SuperAdminDeleteThirdPartyRecord from 'client/shared/graphql-mutations/SuperAdminDeleteThirdPartyRecord.graphql';
import SuperAdminDeleteShortUrl from 'client/shared/graphql-mutations/SuperAdminDeleteShortUrl.graphql';
import SuperAdminUpdateShortUrl from 'client/shared/graphql-mutations/SuperAdminUpdateShortUrl.graphql';
import SuperAdminUploadContentSetShortUrls from 'client/shared/graphql-mutations/SuperAdminUploadContentSetShortUrls.graphql';
import SuperAdminGenerateContentSetShortUrls from 'client/shared/graphql-mutations/SuperAdminGenerateContentSetShortUrls.graphql';
import AdminSendOutreachEmail from 'client/shared/graphql-mutations/AdminSendOutreachEmail.graphql';
import UnsubscribeFromOutreachEmail from 'client/shared/graphql-mutations/UnsubscribeFromOutreachEmail.graphql';
import DeleteEmailList from 'client/shared/graphql-mutations/DeleteEmailList.graphql';
import AdminUpdateEmailList from 'client/shared/graphql-mutations/AdminUpdateEmailList.graphql';
import AdminCreateEmailListWithEmails from 'client/shared/graphql-mutations/AdminCreateEmailList.graphql';
import SuperAdminDeleteContentSetReportTab from 'client/shared/graphql-mutations/SuperAdminDeleteContentSetReportTab.graphql';
import SuperAdminReorderReportTabs from 'client/shared/graphql-mutations/SuperAdminReorderReportTabs.graphql';
import SuperAdminUpdateContentSetReportTab from 'client/shared/graphql-mutations/SuperAdminUpdateContentSetReportTab.graphql';
import SuperAdminCreateContentSetReportTab from 'client/shared/graphql-mutations/SuperAdminCreateContentSetReportTab.graphql';
import SuperadminCreateOrUpdateRecommendedContent from 'client/shared/graphql-mutations/SuperAdminCreateOrUpdateRecommendedContent.graphql';
import RequestBenchmarkProgram from 'client/shared/graphql-mutations/RequestBenchmarkProgram.graphql';
import RequestMagicLinkEmail from 'client/shared/graphql-mutations/RequestMagicLinkEmail.graphql';
import UpdateRespondentProfile from 'client/shared/graphql-mutations/RespondentUpdateProfile.graphql';
import FollowPublishingEntity from 'client/shared/graphql-mutations/FollowPublishingEntity.graphql';
import RetrieveBenchmarkSurveyPdf from 'client/shared/graphql-mutations/RetrieveBenchmarkSurveyPdf.graphql';
import AdminGenerateBenchmarkSurveyPdf from 'client/shared/graphql-mutations/AdminGenerateBenchmarkSurveyPdf.graphql';
import AdminRevertSurveyToDraft from 'client/shared/graphql-mutations/AdminRevertSurveyToDraft.graphql';
import AdminCopyContentSet from 'client/shared/graphql-mutations/AdminCopyContentSet.graphql';
import PasswordReset from 'client/shared/graphql-mutations/PasswordReset.graphql';
import RequestPasswordReset from 'client/shared/graphql-mutations/RequestPasswordReset.graphql';
import AdminResendVerificationEmail from 'client/shared/graphql-mutations/AdminResendVerificationEmail.graphql';
import ApplyRecommendedContentSet from 'client/shared/graphql-mutations/ApplyRecommendedContentSet.graphql';
import SuperAdminSetPurchaseStatus from 'client/shared/graphql-mutations/SuperAdminSetPurchaseStatus.graphql';
import AdminCsvReportUrl from 'client/shared/graphql-mutations/AdminCsvReportUrl.graphql';
import RepostContentSet from 'client/shared/graphql-mutations/RepostContentSet.graphql';
import ScheduleSurvey from 'client/shared/graphql-mutations/ScheduleSurvey.graphql';
import SuperAdminTogglePublishingEntityFeatureSettings from 'client/shared/graphql-mutations/SuperAdminTogglePublishingEntityFeatureSettings.graphql';
import SuperAdminTogglePublishingEntityFeatureSetting from 'client/shared/graphql-mutations/SuperAdminTogglePublishingEntityFeatureSetting.graphql';
import ClientOnlyAnalyticsEvent from 'client/shared/graphql-mutations/CreateClientOnlyAnalyticsEvent.graphql';
import SuperAdminAssocatePublishingEntityWithRegistration from 'client/shared/graphql-mutations/SuperAdminAssociatePublishingEntityWithRegistration.graphql';
import AdminMakeNoPaymentStorePurchase from 'client/shared/graphql-mutations/AdminMakeNoPaymentStorePurchase.graphql';
import ContentSetSocialShare from 'client/shared/graphql-mutations/ContentSetSocialShare.graphql';
import PublishingEntitySocialShare from 'client/shared/graphql-mutations/PublishingEntitySocialShare.graphql';
import AdminEnableTrackDomainFeatureSettings from 'client/shared/graphql-mutations/AdminEnableTrackDomainFeatureSettings.graphql';
import AdminSetShowExampleQuestions from 'client/shared/graphql-mutations/AdminSetShowExampleQuestions.graphql';
import SuperAdminUpdatePublisherAttributes from 'client/shared/graphql-mutations/SuperAdminUpdatePublisherAttributes.graphql';
import AdminSetShortUrl from 'client/shared/graphql-mutations/AdminSetPublishingEntityShortUrl.graphql';
import AdminDeleteSocialConnection from 'client/shared/graphql-mutations/AdminDeleteSocialConnection.graphql';
import AdminUpdateSocialConnection from 'client/shared/graphql-mutations/AdminUpdateSocialConnection.graphql';
import AdminCreateSocialConnection from 'client/shared/graphql-mutations/AdminCreateSocialConnection.graphql';
import AdminUpdatePublishingEntity from 'client/shared/graphql-mutations/AdminUpdatePublishingEntity.graphql';
import AdminSetActivePublishingEntity from 'client/shared/graphql-mutations/AdminSetActivePublishingEntity.graphql';
import PublishOutcome from 'client/shared/graphql-mutations/PublishOutcome.graphql';
import DeleteOutcome from 'client/shared/graphql-mutations/DeleteOutcome.graphql';
import UpdateOutcome from 'client/shared/graphql-mutations/UpdateOutcome.graphql';
import CreateOutcome from 'client/shared/graphql-mutations/CreateOutcome.graphql';
import ScheduleQuestions from 'client/shared/graphql-mutations/AdminScheduleQuestions.graphql';
import SetSurveyContents from 'client/shared/graphql-mutations/AdminSetSurveyContents.graphql';
import SetPollSetQuestion from 'client/shared/graphql-mutations/AdminSetPollSetQuestion.graphql';
import DeletePollSetQuestion from 'client/shared/graphql-mutations/AdminDeletePollSetQuestion.graphql';
import SoftDeleteQuestionSet from 'client/shared/graphql-mutations/SoftDeleteQuestionSet.graphql';
import AddComment from 'client/shared/graphql-mutations/AddComment.graphql';
import ArchiveQuestionSet from 'client/shared/graphql-mutations/ArchiveQuestionSet.graphql';
import DeleteQuestionSet from 'client/shared/graphql-mutations/DeleteQuestionSet.graphql';
import UpdateEmailSettings from 'client/shared/graphql-mutations/UpdateEmailSettings.graphql';
import UpdateQuestionSet from 'client/shared/graphql-mutations/UpdateQuestionSet.graphql';
import CreateQuestionSet from 'client/shared/graphql-mutations/CreateQuestionSet.graphql';
import SocialLogin from 'client/shared/graphql-mutations/SocialLogin.graphql';
import UnUpvoteComment from 'client/shared/graphql-mutations/UnUpvoteComment.graphql';
import UpvoteComment from 'client/shared/graphql-mutations/UpvoteComment.graphql';
import VoteForSurvey from 'client/shared/graphql-mutations/SurveyVote.graphql';
import VoteForQuestion from 'client/shared/graphql-mutations/QuestionVote.graphql';
import Logout from 'client/shared/graphql-mutations/LogOut.graphql';
import EmailSignup from 'client/shared/graphql-mutations/EmailSignup.graphql';
import EmailLogin from 'client/shared/graphql-mutations/EmailLogin.graphql';
import SuperAdminRemoveSuperAdmin from 'client/shared/graphql-mutations/SuperAdminRemoveSuperAdmin.graphql';
import SuperAdminCreateSuperAdmin from 'client/shared/graphql-mutations/SuperAdminCreateSuperAdmin.graphql';
import SuperAdminDeletePublishingEntity from 'client/shared/graphql-mutations/SuperAdminDeletePublishingEntity.graphql';
import SuperAdminCreatePublishingEntity from 'client/shared/graphql-mutations/SuperAdminCreatePublishingEntity.graphql';
import SuperAdminRemovePublisherPresidingArea from 'client/shared/graphql-mutations/SuperAdminRemovePublisherPresidingArea.graphql';
import SuperAdminUpdatePublisherPresidingArea from 'client/shared/graphql-mutations/SuperAdminUpdatePublisherPresidingArea.graphql';
import DeleteAdminFromPublishingEntity from 'client/shared/graphql-mutations/DeleteAdminFromPublishingEntity.graphql';
import UpdateAdminForPublishingEntity from 'client/shared/graphql-mutations/UpdateAdminForPublishingEntity.graphql';
import AddAdminToPublishingEntity from 'client/shared/graphql-mutations/AddAdminToPublishingEntity.graphql';
import SuperAdminResetFeatureSettings from 'client/shared/graphql-mutations/SuperAdminResetFeatureSettings.graphql';
import SuperAdminRejectRegistration from 'client/shared/graphql-mutations/SuperAdminRejectRegistration.graphql';
import SuperAdminApproveRegistration from 'client/shared/graphql-mutations/SuperAdminApproveRegistration.graphql';
import VerifyAdminEmail from 'client/shared/graphql-mutations/VerifyAdminEmail.graphql';
import AdminTrackEventInCrm from 'client/shared/graphql-mutations/AdminTrackEventInCrm.graphql';
import AdminRequestFeatures from 'client/shared/graphql-mutations/AdminRequestFeatures.graphql';
import AdminRegistrationAdditionalData from 'client/shared/graphql-mutations/AdminRegistrationAdditionalData.graphql';
import AdminRegistrationProfileSelection from 'client/shared/graphql-mutations/AdminRegistrationProfileSelection.graphql';
import AdminRegistrationForRespondent from 'client/shared/graphql-mutations/AdminRegistrationForRespondent.graphql';
import AdminRegistrationUserCreation from 'client/shared/graphql-mutations/AdminRegistrationUserCreation.graphql';
import AdminUpdateSimulation from 'client/shared/graphql-mutations/AdminUpdateSimulation.graphql';
import UnsubscribeFromAdminShareEmail from 'client/shared/graphql-mutations/UnsubscribeFromAdminShareEmail.graphql';
import AdminSendShareEmail from 'client/shared/graphql-mutations/AdminSendShareEmail.graphql';
import { mutationInfo, ToMutationReturn } from 'client/shared/containers/mutation';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';

export const adminRegistrationUserCreation = mutationInfo<
  Gql.AdminRegistrationUserCreation,
  Gql.AdminRegistrationUserCreationVariables
>({
  document: AdminRegistrationUserCreation,
});

export const adminRegistrationForRespondent = mutationInfo<
  Gql.AdminRegistrationForRespondent,
  Gql.AdminRegistrationForRespondentVariables
>({
  document: AdminRegistrationForRespondent,
});

export const adminRegistrationProfileSelection = mutationInfo<
  Gql.AdminRegistrationProfileSelection,
  Gql.AdminRegistrationProfileSelectionVariables
>({
  document: AdminRegistrationProfileSelection,
});

export const adminRegistrationAdditionalData = mutationInfo<
  Gql.AdminRegistrationAdditionalData,
  Gql.AdminRegistrationAdditionalDataVariables
>({
  document: AdminRegistrationAdditionalData,
});

export const adminRequestFeatures = mutationInfo<
  Gql.AdminRequestFeatures,
  Gql.AdminRequestFeaturesVariables
>({
  document: AdminRequestFeatures,
});

export const adminTrackEventInCrm = mutationInfo<
  Gql.AdminTrackEventInCrm,
  Gql.AdminTrackEventInCrmVariables
>({
  document: AdminTrackEventInCrm,
});

export const verifyAdminEmail = mutationInfo<
  Gql.VerifyAdminEmail,
  Gql.VerifyAdminEmailVariables
>({
  document: VerifyAdminEmail,
});

export const superAdminApproveRegistration = mutationInfo<
  Gql.SuperAdminApproveRegistration,
  Gql.SuperAdminApproveRegistrationVariables
>({
  document: SuperAdminApproveRegistration,
});

export const superAdminRejectRegistration = mutationInfo<
  Gql.SuperAdminRejectRegistration,
  Gql.SuperAdminRejectRegistrationVariables
>({
  document: SuperAdminRejectRegistration,
});

export const superAdminResetFeatureSettings = mutationInfo<
  Gql.SuperAdminResetFeatureSettings,
  Gql.SuperAdminResetFeatureSettingsVariables
>({
  document: SuperAdminResetFeatureSettings,
});

export const addAdminToPublishingEntity = mutationInfo<
  Gql.AddAdminToPublishingEntity,
  Gql.AddAdminToPublishingEntityVariables
>({
  document: AddAdminToPublishingEntity,
});

export const updateAdminForPublishingEntity = mutationInfo<
  Gql.UpdateAdminForPublishingEntity,
  Gql.UpdateAdminForPublishingEntityVariables
>({
  document: UpdateAdminForPublishingEntity,
});

export const deleteAdminFromPublishingEntity = mutationInfo<
  Gql.DeleteAdminFromPublishingEntity,
  Gql.DeleteAdminFromPublishingEntityVariables
>({
  document: DeleteAdminFromPublishingEntity,
});

export const superAdminUpdatePublisherPresidingArea = mutationInfo<
  Gql.SuperAdminUpdatePublisherPresidingArea,
  Gql.SuperAdminUpdatePublisherPresidingAreaVariables
>({
  document: SuperAdminUpdatePublisherPresidingArea,
});

export const superAdminRemovePublisherPresidingArea = mutationInfo<
  Gql.SuperAdminRemovePublisherPresidingArea,
  Gql.SuperAdminRemovePublisherPresidingAreaVariables
>({
  document: SuperAdminRemovePublisherPresidingArea,
});

export const superAdminCreatePublishingEntity = mutationInfo<
  Gql.SuperAdminCreatePublishingEntity,
  Gql.SuperAdminCreatePublishingEntityVariables
>({
  document: SuperAdminCreatePublishingEntity,
});

export const superAdminDeletePublishingEntity = mutationInfo<
  Gql.SuperAdminDeletePublishingEntity,
  Gql.SuperAdminDeletePublishingEntityVariables
>({
  document: SuperAdminDeletePublishingEntity,
});

export const superAdminCreateSuperAdmin = mutationInfo<
  Gql.SuperAdminCreateSuperAdmin,
  Gql.SuperAdminCreateSuperAdminVariables
>({
  document: SuperAdminCreateSuperAdmin,
});

export const superAdminRemoveSuperAdmin = mutationInfo<
  Gql.SuperAdminRemoveSuperAdmin,
  Gql.SuperAdminRemoveSuperAdminVariables
>({
  document: SuperAdminRemoveSuperAdmin,
});

export const emailLogin = mutationInfo<Gql.EmailLogin, Gql.EmailLoginVariables>({
  document: EmailLogin,
});

export const emailSignup = mutationInfo<Gql.EmailSignup, Gql.EmailSignupVariables>({
  document: EmailSignup,
});

export const logout = mutationInfo<Gql.LogOut, never>({
  document: Logout,
});

export const voteForQuestion = mutationInfo<
  Gql.VoteForQuestion,
  Gql.VoteForQuestionVariables
>({
  document: VoteForQuestion,
});
export const addComment = mutationInfo<Gql.AddComment, Gql.AddCommentVariables>({
  document: AddComment,
});

export const voteForSurvey = mutationInfo<
  Gql.VoteForSurvey,
  Gql.VoteForSurveyVariables
>({
  document: VoteForSurvey,
});

export const upvoteComment = mutationInfo<
  Gql.UpvoteComment,
  Gql.UpvoteCommentVariables
>({
  document: UpvoteComment,
});

export const unUpvoteComment = mutationInfo<
  Gql.UnUpvoteComment,
  Gql.UnUpvoteCommentVariables
>({
  document: UnUpvoteComment,
});

export const socialLogin = mutationInfo<Gql.SocialLogin, Gql.SocialLoginVariables>({
  document: SocialLogin,
});

export const createQuestionSet = mutationInfo<
  Gql.CreateQuestionSet,
  Gql.CreateQuestionSetVariables
>({
  document: CreateQuestionSet,
});

export const updateQuestionSet = mutationInfo<
  Gql.UpdateQuestionSet,
  Gql.UpdateQuestionSetVariables
>({
  document: UpdateQuestionSet,
});

export const updateEmailSettings = mutationInfo<
  Gql.UpdateEmailSettings,
  Gql.UpdateEmailSettingsVariables
>({
  document: UpdateEmailSettings,
});

export const deleteQuestionSet = mutationInfo<
  Gql.DeleteQuestionSet,
  Gql.DeleteQuestionSetVariables
>({
  document: DeleteQuestionSet,
});

export const archiveQuestionSet = mutationInfo<
  Gql.ArchiveQuestionSet,
  Gql.ArchiveQuestionSetVariables
>({
  document: ArchiveQuestionSet,
});

export const softDeleteQuestionSet = mutationInfo<
  Gql.SoftDeleteQuestionSet,
  Gql.SoftDeleteQuestionSetVariables
>({
  document: SoftDeleteQuestionSet,
});

export const deletePollSetQuestion = mutationInfo<
  Gql.DeletePollSetQuestion,
  Gql.DeletePollSetQuestionVariables
>({
  document: DeletePollSetQuestion,
});

export const setPollSetQuestion = mutationInfo<
  Gql.SetPollSetQuestion,
  Gql.SetPollSetQuestionVariables
>({
  document: SetPollSetQuestion,
});

export const setSurveyContents = mutationInfo<
  Gql.SetSurveyContents,
  Gql.SetSurveyContentsVariables
>({
  document: SetSurveyContents,
});

export const scheduleQuestions = mutationInfo<
  Gql.ScheduleQuestions,
  Gql.ScheduleQuestionsVariables
>({
  document: ScheduleQuestions,
});

export const createOutcome = mutationInfo<
  Gql.CreateOutcome,
  Gql.CreateOutcomeVariables
>({
  document: CreateOutcome,
});

export const updateOutcome = mutationInfo<
  Gql.UpdateOutcome,
  Gql.UpdateOutcomeVariables
>({
  document: UpdateOutcome,
});

export const deleteOutcome = mutationInfo<
  Gql.DeleteOutcome,
  Gql.DeleteOutcomeVariables
>({
  document: DeleteOutcome,
});

export const publishOutcome = mutationInfo<
  Gql.PublishOutcome,
  Gql.PublishOutcomeVariables
>({
  document: PublishOutcome,
});

export const adminSetActivePublishingEntity = mutationInfo<
  Gql.AdminSetActivePublishingEntity,
  Gql.AdminSetActivePublishingEntityVariables
>({
  document: AdminSetActivePublishingEntity,
});

export const adminUpdatePublishingEntity = mutationInfo<
  Gql.AdminUpdatePublishingEntity,
  Gql.AdminUpdatePublishingEntityVariables
>({
  document: AdminUpdatePublishingEntity,
});

export const adminCreateSocialConnection = mutationInfo<
  Gql.AdminCreateSocialConnection,
  Gql.AdminCreateSocialConnectionVariables
>({
  document: AdminCreateSocialConnection,
});

export const adminUpdateSocialConnection = mutationInfo<
  Gql.AdminUpdateSocialConnection,
  Gql.AdminUpdateSocialConnectionVariables
>({
  document: AdminUpdateSocialConnection,
});

export const adminDeleteSocialConnection = mutationInfo<
  Gql.AdminDeleteSocialConnection,
  Gql.AdminDeleteSocialConnectionVariables
>({
  document: AdminDeleteSocialConnection,
});

export const adminSetShortUrl = mutationInfo<
  Gql.AdminSetPublishingEntityShortUrl,
  Gql.AdminSetPublishingEntityShortUrlVariables
>({
  document: AdminSetShortUrl,
});

export const superAdminUpdatePublisherAttributes = mutationInfo<
  Gql.SuperAdminUpdatePublisherAttributes,
  Gql.SuperAdminUpdatePublisherAttributesVariables
>({
  document: SuperAdminUpdatePublisherAttributes,
});

export const adminSetShowExampleQuestions = mutationInfo<
  Gql.AdminSetShowExampleQuestions,
  Gql.AdminSetShowExampleQuestionsVariables
>({
  document: AdminSetShowExampleQuestions,
});

export const adminEnableTrackDomainFeatureSettings = mutationInfo<
  Gql.AdminEnableTrackDomainFeatureSettings,
  Gql.AdminEnableTrackDomainFeatureSettingsVariables
>({
  document: AdminEnableTrackDomainFeatureSettings,
});

export const publishingEntitySocialShare = mutationInfo<
  Gql.PublishingEntitySocialShare,
  Gql.PublishingEntitySocialShareVariables
>({
  document: PublishingEntitySocialShare,
});

export const contentSetSocialShare = mutationInfo<
  Gql.ContentSetSocialShare,
  Gql.ContentSetSocialShareVariables
>({
  document: ContentSetSocialShare,
});

export const adminMakeNoPaymentStorePurchase = mutationInfo<
  Gql.AdminMakeNoPaymentStorePurchase,
  Gql.AdminMakeNoPaymentStorePurchaseVariables
>({
  document: AdminMakeNoPaymentStorePurchase,
});

export const superAdminAssocatePublishingEntityWithRegistration = mutationInfo<
  Gql.SuperAdminAssociatePublishingEntityWithRegistration,
  Gql.SuperAdminAssociatePublishingEntityWithRegistrationVariables
>({
  document: SuperAdminAssocatePublishingEntityWithRegistration,
});

export const clientOnlyAnalyticsEvent = mutationInfo<
  Gql.CreateClientOnlyAnalyticsEvent,
  Gql.CreateClientOnlyAnalyticsEventVariables
>({
  document: ClientOnlyAnalyticsEvent,
});

export const superAdminTogglePublishingEntityFeatureSetting = mutationInfo<
  Gql.SuperAdminTogglePublishingEntityFeatureSetting,
  Gql.SuperAdminTogglePublishingEntityFeatureSettingVariables
>({
  document: SuperAdminTogglePublishingEntityFeatureSetting,
});

export const superAdminTogglePublishingEntityFeatureSettings = mutationInfo<
  Gql.SuperAdminTogglePublishingEntityFeatureSettings,
  Gql.SuperAdminTogglePublishingEntityFeatureSettingsVariables
>({
  document: SuperAdminTogglePublishingEntityFeatureSettings,
});

export const scheduleSurvey = mutationInfo<
  Gql.ScheduleSurvey,
  Gql.ScheduleSurveyVariables
>({
  document: ScheduleSurvey,
});

export const repostContentSet = mutationInfo<
  Gql.RepostContentSet,
  Gql.RepostContentSetVariables
>({
  document: RepostContentSet,
});

export const adminCsvReportUrl = mutationInfo<
  Gql.AdminCsvReportUrl,
  Gql.AdminCsvReportUrlVariables
>({
  document: AdminCsvReportUrl,
});

export const superAdminSetPurchaseStatus = mutationInfo<
  Gql.SuperAdminSetPurchaseStatus,
  Gql.SuperAdminSetPurchaseStatusVariables
>({
  document: SuperAdminSetPurchaseStatus,
});

export const applyRecommendedContentSet = mutationInfo<
  Gql.ApplyRecommendedContentSet,
  Gql.ApplyRecommendedContentSetVariables
>({
  document: ApplyRecommendedContentSet,
});

export const adminResendVerificationEmail = mutationInfo<
  Gql.AdminResendVerificationEmail,
  {}
>({
  document: AdminResendVerificationEmail,
});

export const requestPasswordReset = mutationInfo<
  Gql.RequestPasswordReset,
  Gql.RequestPasswordResetVariables
>({
  document: RequestPasswordReset,
});

export const passwordReset = mutationInfo<
  Gql.PasswordReset,
  Gql.PasswordResetVariables
>({
  document: PasswordReset,
});

export const adminCopyContentSet = mutationInfo<
  Gql.AdminCopyContentSet,
  Gql.AdminCopyContentSetVariables
>({
  document: AdminCopyContentSet,
});

export const adminRevertSurveyToDraft = mutationInfo<
  Gql.AdminRevertSurveyToDraft,
  Gql.AdminRevertSurveyToDraftVariables
>({
  document: AdminRevertSurveyToDraft,
});

export const adminGenerateBenchmarkSurveyPdf = mutationInfo<
  Gql.AdminGenerateBenchmarkSurveyPdf,
  Gql.AdminGenerateBenchmarkSurveyPdfVariables
>({
  document: AdminGenerateBenchmarkSurveyPdf,
});

export const retrieveBenchmarkSurveyPdf = mutationInfo<
  Gql.RetrieveBenchmarkSurveyPdf,
  Gql.RetrieveBenchmarkSurveyPdfVariables
>({
  document: RetrieveBenchmarkSurveyPdf,
});

export const followPublishingEntity = mutationInfo<
  Gql.FollowPublishingEntity,
  Gql.FollowPublishingEntityVariables
>({
  document: FollowPublishingEntity,
});

export const updateRespondentProfile = mutationInfo<
  Gql.RespondentUpdateProfile,
  Gql.RespondentUpdateProfileVariables
>({
  document: UpdateRespondentProfile,
});

export const requestMagicLinkEmail = mutationInfo<
  Gql.RequestMagicLinkEmail,
  Gql.RequestMagicLinkEmailVariables
>({
  document: RequestMagicLinkEmail,
});

export const requestBenchmarkProgram = mutationInfo<
  Gql.RequestBenchmarkProgram,
  Gql.RequestBenchmarkProgramVariables
>({
  document: RequestBenchmarkProgram,
});
export const superadminCreateOrUpdateRecommendedContent = mutationInfo<
  Gql.SuperAdminCreateOrUpdateRecommendedContent,
  Gql.SuperAdminCreateOrUpdateRecommendedContentVariables
>({
  document: SuperadminCreateOrUpdateRecommendedContent,
});

export const superAdminCreateContentSetReportTab = mutationInfo<
  Gql.SuperAdminCreateContentSetReportTab,
  Gql.SuperAdminCreateContentSetReportTabVariables
>({
  document: SuperAdminCreateContentSetReportTab,
});

export const superAdminUpdateContentSetReportTab = mutationInfo<
  Gql.SuperAdminUpdateContentSetReportTab,
  Gql.SuperAdminUpdateContentSetReportTabVariables
>({
  document: SuperAdminUpdateContentSetReportTab,
});

export const superAdminReorderReportTabs = mutationInfo<
  Gql.SuperAdminReorderReportTabs,
  Gql.SuperAdminReorderReportTabsVariables
>({
  document: SuperAdminReorderReportTabs,
});

export const superAdminDeleteContentSetReportTab = mutationInfo<
  Gql.SuperAdminDeleteContentSetReportTab,
  Gql.SuperAdminDeleteContentSetReportTabVariables
>({
  document: SuperAdminDeleteContentSetReportTab,
});

export const adminCreateEmailListWithEmails = mutationInfo<
  Gql.AdminCreateEmailList,
  Gql.AdminCreateEmailListVariables
>({
  document: AdminCreateEmailListWithEmails,
});

export const adminUpdateEmailList = mutationInfo<
  Gql.AdminUpdateEmailList,
  Gql.AdminUpdateEmailListVariables
>({
  document: AdminUpdateEmailList,
});

export const deleteEmailList = mutationInfo<
  Gql.DeleteEmailList,
  Gql.DeleteEmailListVariables
>({
  document: DeleteEmailList,
});

export const unsubscribeFromOutreachEmail = mutationInfo<
  Gql.UnsubscribeFromOutreachEmail,
  Gql.UnsubscribeFromOutreachEmailVariables
>({
  document: UnsubscribeFromOutreachEmail,
});
export const unsubscribeFromAdminShareEmail = mutationInfo<
  Gql.UnsubscribeFromAdminShareEmail,
  Gql.UnsubscribeFromAdminShareEmailVariables
>({
  document: UnsubscribeFromAdminShareEmail,
});
export const adminSendOutreachEmail = mutationInfo<
  Gql.AdminSendOutreachEmail,
  Gql.AdminSendOutreachEmailVariables
>({
  document: AdminSendOutreachEmail,
});

export const superAdminGenerateContentSetShortUrls = mutationInfo<
  Gql.SuperAdminGenerateContentSetShortUrls,
  Gql.SuperAdminGenerateContentSetShortUrlsVariables
>({
  document: SuperAdminGenerateContentSetShortUrls,
});

export const superAdminUploadContentSetShortUrls = mutationInfo<
  Gql.SuperAdminUploadContentSetShortUrls,
  Gql.SuperAdminUploadContentSetShortUrlsVariables
>({
  document: SuperAdminUploadContentSetShortUrls,
});

export const superAdminUpdateShortUrl = mutationInfo<
  Gql.SuperAdminUpdateShortUrl,
  Gql.SuperAdminUpdateShortUrlVariables
>({
  document: SuperAdminUpdateShortUrl,
});

export const superAdminDeleteShortUrl = mutationInfo<
  Gql.SuperAdminDeleteShortUrl,
  Gql.SuperAdminDeleteShortUrlVariables
>({
  document: SuperAdminDeleteShortUrl,
});

export const superAdminDeleteThirdPartyRecord = mutationInfo<
  Gql.SuperAdminDeleteThirdPartyRecord,
  Gql.SuperAdminDeleteThirdPartyRecordVariables
>({
  document: SuperAdminDeleteThirdPartyRecord,
});

export const superAdminCreateThirdPartyRecord = mutationInfo<
  Gql.SuperAdminCreateThirdPartyRecord,
  Gql.SuperAdminCreateThirdPartyRecordVariables
>({
  document: SuperAdminCreateThirdPartyRecord,
});

export const superAdminUpdateThirdPartyRecord = mutationInfo<
  Gql.SuperAdminUpdateThirdPartyRecord,
  Gql.SuperAdminUpdateThirdPartyRecordVariables
>({
  document: SuperAdminUpdateThirdPartyRecord,
});

export const superAdminUpsertReportRequest = mutationInfo<
  Gql.SuperAdminUpsertBenchmarkReportRequest,
  Gql.SuperAdminUpsertBenchmarkReportRequestVariables
>({
  document: SuperAdminUpsertReportRequest,
});

export const superAdminDeleteReportRequest = mutationInfo<
  Gql.SuperAdminDeleteBenchmarkReportRequest,
  Gql.SuperAdminDeleteBenchmarkReportRequestVariables
>({
  document: SuperAdminDeleteReportRequest,
});

export const superAdminPublishReportRequest = mutationInfo<
  Gql.SuperAdminPublishBenchmarkReportRequest,
  Gql.SuperAdminPublishBenchmarkReportRequestVariables
>({
  document: SuperAdminPublishReportRequest,
});

export const superAdminDownloadBenchmarkReportRequestData = mutationInfo<
  Gql.SuperAdminDownloadBenchmarkReporRequesttData,
  Gql.SuperAdminDownloadBenchmarkReporRequesttDataVariables
>({
  document: SuperAdminDownloadBenchmarkReportRequestData,
});

export const reorderQuestionSet = mutationInfo<
  Gql.ReorderQuestionSet,
  Gql.ReorderQuestionSetVariables
>({
  document: ReorderQuestionSet,
});

export const superAdminImportTranslations = mutationInfo<
  Gql.SuperAdminImportTranslations,
  Gql.SuperAdminImportTranslationsVariables
>({
  document: SuperAdminImportTranslations,
});

export const adminSendSharePolcoEmail = mutationInfo<
  Gql.AdminSendShareEmail,
  Gql.AdminSendShareEmailVariables
>({
  document: AdminSendShareEmail,
});

export const adminSaveNewVisualization = mutationInfo<
  Gql.AdminSaveNewVisualization,
  Gql.AdminSaveNewVisualizationVariables
>({
  document: AdminSaveNewVisualization,
});

export const adminUpdateVisualizationState = mutationInfo<
  Gql.AdminUpdateVisualizationState,
  Gql.AdminUpdateVisualizationStateVariables
>({
  document: AdminUpdateVisualizationState,
});
export interface QuestionVotingMutationReturns {
  readonly voteForQuestion: ToMutationReturn<typeof voteForQuestion>;
  readonly addComment?: ToMutationReturn<typeof addComment>;
  readonly upvoteComment?: ToMutationReturn<typeof upvoteComment>;
  readonly unUpvoteComment?: ToMutationReturn<typeof unUpvoteComment>;
  readonly logout?: ToMutationReturn<typeof logout>;
  readonly followOnVoting?: ToMutationReturn<typeof followPublishingEntity>;
}

export interface SurveyVotingMutationReturns {
  readonly voteForSurvey: ToMutationReturn<typeof voteForSurvey>;
  readonly logout: ToMutationReturn<typeof logout>;
  readonly followOnVoting: ToMutationReturn<typeof followPublishingEntity>;
}

export interface RegistrationMutationReturns {
  readonly emailLogin: ToMutationReturn<typeof emailLogin>;
  readonly emailSignup: ToMutationReturn<typeof emailSignup>;
  readonly socialLogin: ToMutationReturn<typeof socialLogin>;
}
export const createIdea = mutationInfo<Gql.CreateIdea, Gql.CreateIdeaVariables>({
  document: CreateIdea,
});
export const interactWithIdea = mutationInfo<
  Gql.InteractWithIdea,
  Gql.InteractWithIdeaVariables
>({
  document: InteractWithIdea,
});

export const createDemoUser = mutationInfo<
  Gql.CreateDemoUser,
  Gql.CreateDemoUserVariables
>({
  document: CreateDemoUser,
});

export const createAndLoginDemoUser = mutationInfo<
  Gql.CreateAndLoginDemoUser,
  Gql.CreateAndLoginDemoUserVariables
>({
  document: CreateAndLoginDemoUser,
});

export const adminDuplicateSimulation = mutationInfo<
  Gql.AdminDuplicateSimulation,
  Gql.AdminDuplicateSimulationVariables
>({
  document: AdminDuplicateSimulation,
});

export const adminUpdateSimulation = mutationInfo<
  Gql.AdminUpdateSimulation,
  Gql.AdminUpdateSimulationVariables
>({
  document: AdminUpdateSimulation,
});

export const adminAiAssistantPostMessage = mutationInfo<
  Gql.AdminAiAssistantPostMessage,
  Gql.AdminAiAssistantPostMessageVariables
>({
  document: AdminAiAssistantPostMessage,
});

export const adminAiAssistantCancelProcessing = mutationInfo<
  Gql.AdminAiAssistantCancelProcessing,
  Gql.AdminAiAssistantCancelProcessingVariables
>({
  document: AdminAiAssistantCancelProcessing,
});
