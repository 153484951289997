import { SharedConfig, PubliclyVisibleData } from 'config';
import { Conference } from 'core';

export function getConfig(): SharedConfig {
  if (process.env.NODE_ENV === 'test') {
    return testConfig;
  }
  return (window as any).POLCO_CONFIG;
}

function getPubliclyVisibleConfig<T>(value: T): PubliclyVisibleData<T> {
  return value as PubliclyVisibleData<T>;
}

const testConfig: SharedConfig = {
  integrations: {
    appcuesId: getPubliclyVisibleConfig(''),
    cloudinaryUploadPreset: getPubliclyVisibleConfig(''),
    facebookAppId: getPubliclyVisibleConfig(''),
    facebookAdminPixelId: getPubliclyVisibleConfig(''),
    facebookRespondentPixelId: getPubliclyVisibleConfig(''),
    googleAnalyticsTrackingId: getPubliclyVisibleConfig(''),
    googleApiKey: getPubliclyVisibleConfig(''),
    googleClientId: getPubliclyVisibleConfig(''),
    googleMapsApiKey: getPubliclyVisibleConfig(''),
    googleTagManagerId: getPubliclyVisibleConfig(''),
    heapAppId: getPubliclyVisibleConfig(''),
    rollbarClientToken: getPubliclyVisibleConfig(''),
    hubspotPortalId: getPubliclyVisibleConfig(''),
    publishToHubspot: getPubliclyVisibleConfig(false),
    stripeApiKey: getPubliclyVisibleConfig(''),
    tinyApiKey: getPubliclyVisibleConfig(''),
  },
  client: {
    debug: getPubliclyVisibleConfig(false),
    messagingWell: getPubliclyVisibleConfig(''),
  },
  envFeatures: {
    defaultMultipleChoiceScales: getPubliclyVisibleConfig(false),
    showAllVoteCounts: getPubliclyVisibleConfig(true),
    conferencePages: getPubliclyVisibleConfig([Conference.UNKNOWN]),
    environmentBannerName: getPubliclyVisibleConfig(null),
    environmentBannerEnabled: getPubliclyVisibleConfig(false),
    backendAdminSocialSharing: getPubliclyVisibleConfig(true),
    significanceMarginsURL: getPubliclyVisibleConfig(''),
    domainStatisticsFilterSortEnabled: getPubliclyVisibleConfig(true),
    balancingActBaseUrl: getPubliclyVisibleConfig(''),
    balancingActApiBaseUrl: getPubliclyVisibleConfig(''),
    balancingActAdminBaseUrl: getPubliclyVisibleConfig('https://localhost:8082'),
    contentLibraryDomainFilterEnabled: getPubliclyVisibleConfig(true),
    enableResidentIdeas: getPubliclyVisibleConfig(true),
    enableIdeaVoting: getPubliclyVisibleConfig(true),
    showTrackUpsell: getPubliclyVisibleConfig(true),
    superAdminReportBetaEnabled: getPubliclyVisibleConfig(true),
    pollyFreeQuestionLimit: getPubliclyVisibleConfig(5),
    freeTrialTierKey: getPubliclyVisibleConfig('Polly-Free-Trial'),
    maxRespondentsForDownloadableReport: getPubliclyVisibleConfig(500),
    selfServiceBudgetSimId: getPubliclyVisibleConfig(1234),
  },
  localhostRegexes: getPubliclyVisibleConfig([]),
  siteUrl: getPubliclyVisibleConfig('http://polco-test.us'),
  apiGatewayUrl: getPubliclyVisibleConfig('http://localhost:2424'),
  infoSiteUrl: getPubliclyVisibleConfig(''),
  contactPageUrl: getPubliclyVisibleConfig(''),
  websocketUri: getPubliclyVisibleConfig(''),
  stripeHelpUrl: getPubliclyVisibleConfig(''),
  polcoConnectUrl: getPubliclyVisibleConfig(''),
  demoPublisherSlug: getPubliclyVisibleConfig('demo-city'),
  demoAdminEmail: getPubliclyVisibleConfig('demoAdminUser@polco.us'),
  demoUserExpirationDays: getPubliclyVisibleConfig(90),
  isDemoEnv: getPubliclyVisibleConfig(false),
};
